import { groupBy } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Layout from 'components/core/Layout';
import Loader from 'components/core/Loader';
import DetailsSidebar from 'components/customer/DetailsSidebar';
import CreateUpdateModal from 'components/customers/CreateUpdateModal';
import FilesCollection from 'components/files/FilesCollection';
import FilesTable from 'components/files/FilesTable';
import PicturesListModal from 'components/files/PicturesListModal';
import UploadModal from 'components/files/UploadModal';
import Topbar from 'components/layout/Topbar';

import { useGetCustomer } from 'lib/useCustomer';
import { useGetFilesByCustomer } from 'lib/useFile';

import { FileType } from 'models/file';

export default function SingleCustomer() {
  const params = useParams<{ specialId?: string }>();

  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isViewPicturesOpen, setIsViewPicturesOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [prefilledFileType, setPrefilledFileType] =
    useState<FileType>('PROPOSAL');

  const {
    customer,
    isLoadingCustomer,
    mutate: mutateCustomer,
  } = useGetCustomer(params.specialId ?? '');

  const {
    files,
    isLoadingFiles,
    mutate: mutateFiles,
  } = useGetFilesByCustomer(params.specialId ?? '');

  const isLoading = isLoadingCustomer || isLoadingFiles;

  const groupedByFiles = groupBy(files, (f) => f.fileType);

  const onOpenUploadModal = (type: FileType) => {
    setPrefilledFileType(type);

    setIsViewPicturesOpen(false);

    setIsUploadOpen(true);
  };

  const onCustomerUpdated = async () => {
    await mutateCustomer();
  };

  const onFilesUploaded = async () => {
    await mutateFiles();
  };

  const onCompleteEdit = async () => {
    await mutateFiles();
  };

  if (isLoading)
    return (
      <Layout
        seoTitle='Customer - Imperial Building'
        seoDescription=''
      >
        <Topbar />

        <Loader />
      </Layout>
    );

  if (!customer) {
    return (
      <Layout
        seoTitle='No customer - Imperial Building'
        seoDescription=''
      >
        <Topbar />

        <section className='single-customer-section'>
          <div className='customer-header-wrapper'>
            <p>No customer found here!</p>
          </div>
        </section>
      </Layout>
    );
  }

  return (
    <Layout seoTitle='Customer - Imperial Building' seoDescription=''>
      <Topbar />

      <section className='single-customer-section'>
        <DetailsSidebar
          customer={customer}
          setIsEditOpen={setIsEditOpen}
        />

        <div className='customer-content-wrapper'>
          <div className='content-invoice-block'>
            <div className='content-header-wrapper'>
              <h1 className='content-title'>Invoices</h1>

              <button
                className='btn-link'
                onClick={() => onOpenUploadModal('INVOICE')}
              >
                Upload
              </button>
            </div>

            <FilesTable
              files={groupedByFiles['INVOICE'] ?? []}
              customer={customer}
              onCompleteEdit={onCompleteEdit}
            />
          </div>

          <div className='content-proposal-block'>
            <div className='content-header-wrapper'>
              <h1 className='content-title'>Proposals</h1>

              <button
                className='btn-link'
                onClick={() => onOpenUploadModal('PROPOSAL')}
              >
                Upload
              </button>
            </div>

            <FilesTable
              files={groupedByFiles['PROPOSAL'] ?? []}
              customer={customer}
              onCompleteEdit={onCompleteEdit}
            />
          </div>

          <div className='content-photos-block'>
            <div className='content-header-wrapper'>
              <h1 className='content-title'>Project photos</h1>

              <button
                className='btn-link'
                onClick={() => onOpenUploadModal('PICTURE')}
              >
                Upload
              </button>
            </div>

            <FilesCollection
              files={groupedByFiles['PICTURE'] ?? []}
              onViewAll={() => setIsViewPicturesOpen(true)}
            />
          </div>

          <div className='content-other-block'>
            <div className='content-header-wrapper'>
              <h1 className='content-title'>Other files</h1>

              <button
                className='btn-link'
                onClick={() => onOpenUploadModal('OTHER')}
              >
                Upload
              </button>
            </div>

            <FilesTable
              files={groupedByFiles['OTHER'] ?? []}
              customer={customer}
              onCompleteEdit={onCompleteEdit}
            />
          </div>
        </div>
      </section>

      <CreateUpdateModal
        existingCustomer={customer}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        onNext={onCustomerUpdated}
      />

      <UploadModal
        prefilled={{
          customerSpecialId: customer.specialId,
          customerInfo: `${customer.firstName} ${customer.lastName}`,
          fileType: prefilledFileType,
          files: [],
        }}
        isOpen={isUploadOpen}
        setIsOpen={setIsUploadOpen}
        onNext={onFilesUploaded}
      />

      <PicturesListModal
        pictures={groupedByFiles['PICTURE'] ?? []}
        isOpen={isViewPicturesOpen}
        setIsOpen={setIsViewPicturesOpen}
        onOpenUpload={() => onOpenUploadModal('PICTURE')}
      />
    </Layout>
  );
}
