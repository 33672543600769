import { Pagination, PaginationProps } from 'antd';

interface Props {
  page: number;
  totalItems: number;
  itemsPerPage: number;
  onSelect: (newPage: number) => void;
}

export default function TablePagination({
  page,
  totalItems,
  itemsPerPage,
  onSelect,
}: Props) {
  const onChange = (newPage: number) => {
    onSelect(newPage);
  };

  return (
    <div className='table-paginate-wrapper'>
      <p className='paginate-total-text'>
        {totalItems} total ({itemsPerPage} per page)
      </p>

      <Pagination
        current={page}
        onChange={onChange}
        total={totalItems}
        pageSize={itemsPerPage}
        showSizeChanger={false}
      />
    </div>
  );
}
