import { useState } from 'react';

import Layout from 'components/core/Layout';
import Loader from 'components/core/Loader';
import FilesTable from 'components/files/FilesTable';
import UploadModal from 'components/files/UploadModal';
import Topbar from 'components/layout/Topbar';

import { useGetFilesByPage } from 'lib/useFile';

const FilesHeader = ({
  setIsOpen,
}: {
  setIsOpen: (isOpen: boolean) => void;
}) => {
  return (
    <div className='files-header-wrapper'>
      <h1 className='files-title'>Files</h1>

      <button className='btn-primary' onClick={() => setIsOpen(true)}>
        + Add file
      </button>
    </div>
  );
};

export default function Files() {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);

  const { filesByPage, isLoadingFiles, mutate } =
    useGetFilesByPage(page);

  const isLoading = isLoadingFiles;

  const onSelectPage = (newPage: number) => {
    setPage(newPage);
  };

  const onFilesUploaded = async () => {
    await mutate();
  };

  if (isLoading || !filesByPage)
    return (
      <Layout seoTitle='Files - Imperial Building' seoDescription=''>
        <Topbar />

        <Loader />
      </Layout>
    );

  return (
    <Layout seoTitle='Files - Imperial Building' seoDescription=''>
      <Topbar />

      <section className='files-section'>
        <FilesHeader setIsOpen={setIsOpen} />

        <FilesTable
          filesByPage={filesByPage}
          files={filesByPage.files}
          showPagination
          showCustomer
          onSelectNewPage={onSelectPage}
        />
      </section>

      <UploadModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onNext={onFilesUploaded}
      />
    </Layout>
  );
}
