import { Dropdown, MenuProps } from 'antd';

import outcomeIcon from 'assets/svg/icons/outcome-icon.svg';
import settingsIcon from 'assets/svg/icons/settings-icon.svg';

import { useState } from 'react';
import authenticationService from 'services/authenticationService';
import TopbarSearch from './TopbarSearch';

export default function Topbar() {
  // TODO: Add in v1.1
  // const [isUploadOpen, setIsUploadOpen] = useState(false);
  // const [isCreateOpen, setIsCreateOpen] = useState(false);

  const onLogout = async () => {
    await authenticationService.logout();
  };

  const onCreatedCustomer = async (specialId: string) => {};

  const onUploadedFile = async () => {};

  const settingsItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <button className='topbar-btn' onClick={onLogout}>
            <img
              src={outcomeIcon}
              alt='logout icon'
              className='topbar-icon'
            />
            Logout
          </button>
        </>
      ),
    },
  ];

  // TODO: v1.1 look into adding this piece more easily
  // const addItems: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     type: 'group',
  //     label: 'Add actions',
  //     children: [
  //       {
  //         key: '1-1',
  //         label: (
  //           <button
  //             className='topbar-btn'
  //             onClick={() => setIsCreateOpen(true)}
  //           >
  //             Create customer
  //           </button>
  //         ),
  //       },
  //       {
  //         key: '1-2',
  //         label: (
  //           <button
  //             className='topbar-btn'
  //             onClick={() => setIsUploadOpen(true)}
  //           >
  //             Upload file(s)
  //           </button>
  //         ),
  //       },
  //     ],
  //   },
  // ];

  return (
    <>
      <section className='topbar-section'>
        <div className='topbar-left-content'>
          <TopbarSearch />
        </div>

        <div className='topbar-right-content'>
          <Dropdown
            menu={{ items: settingsItems }}
            trigger={['click']}
            className='topbar-dropdown'
            placement='bottomRight'
          >
            <button
              className='topbar-dropdown-btn'
              onClick={(e) => e.preventDefault()}
            >
              <img
                src={settingsIcon}
                alt='settings icon'
                className='btn-icon'
              />
            </button>
          </Dropdown>

          {/* // TODO: Add in more properly in v1.1 */}
          {/* <Dropdown
            menu={{ items: addItems }}
            trigger={['click']}
            className='topbar-dropdown'
            placement='bottomRight'
          >
            <button
              className='topbar-dropdown-btn'
              onClick={(e) => e.preventDefault()}
            >
              <img
                src={addIcon}
                alt='add icon'
                className='btn-icon'
              />
            </button>
          </Dropdown> */}
        </div>
      </section>

      {/* <CreateUpdateModal
        isOpen={isCreateOpen}
        setIsOpen={setIsCreateOpen}
        onNext={onCreatedCustomer}
      />

      <UploadModal
        isOpen={isUploadOpen}
        setIsOpen={setIsUploadOpen}
        onNext={onUploadedFile}
      /> */}
    </>
  );
}
