import { Modal } from 'antd';

interface Props {
  id?: number;
  title?: string;
  question: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete: (id?: number) => void;
}

export default function DeleteModal({
  id,
  title = 'Caution',
  question,
  isOpen,
  onClose,
  onDelete,
}: Props) {
  return (
    <Modal
      title={title}
      open={isOpen}
      closeIcon={null}
      closable={false}
      footer={null}
      width={450}
      className='app-modal'
    >
      <div className='modal-form'>
        <div className='modal-body'>
          <p className='modal-text'>{question}</p>
        </div>

        <div className='modal-footer'>
          <button
            className='btn-secondary'
            onClick={onClose}
            type='button'
          >
            Cancel
          </button>

          <button
            className='btn-primary destructive-text'
            onClick={() => onDelete(id)}
            type='submit'
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
}
