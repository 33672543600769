import { CustomerEmail, UnsavedCustomerEmail } from 'models/customer';
import { createApiClient } from './authenticationService';

const updatePrimaryEmail = async (
  CustomerEmailId: number,
  customerId: number
) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/email/primary', {
      id: CustomerEmailId,
      customerId,
    });
  } catch (error) {
    throw error;
  }
};

const createEmail = async (
  address: Partial<UnsavedCustomerEmail>
) => {
  try {
    const client = await createApiClient();

    await client.post('/customer/email', address);
  } catch (error) {
    throw error;
  }
};

const updateEmail = async (address: Partial<CustomerEmail>) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/email', address);
  } catch (error) {
    throw error;
  }
};

const deleteEmail = async (id: number) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/email', {
      id,
      active: false,
    });
  } catch (error) {
    throw error;
  }
};

export default {
  updatePrimaryEmail,
  createEmail,
  updateEmail,
  deleteEmail,
};
