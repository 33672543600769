import useMessage from 'antd/es/message/useMessage';
import { useState } from 'react';

import rightArrowIcon from 'assets/svg/icons/arrow-right-icon.svg';

import AltDetailsDrawer from './AltDetailsDrawer';

import { prettyPhoneNumber } from 'lib/phone';

import { RetrievedCustomer } from 'models/customer';

interface Props {
  customer: RetrievedCustomer;
  setIsEditOpen: (isOpen: boolean) => void;
}

export default function DetailsSidebar({
  customer,
  setIsEditOpen,
}: Props) {
  const [messageApi, contextHolder] = useMessage();

  const [isAltOpen, setIsAltOpen] = useState(false);

  const onCopyCustomerId = () => {
    navigator.clipboard.writeText(customer.specialId ?? '');

    messageApi.open({
      type: 'success',
      content: 'Copied customer id!',
    });
  };

  const onOpenAltDetails = () => {
    setIsAltOpen(true);
  };

  return (
    <>
      {contextHolder}

      <div className='customer-sidebar-wrapper'>
        <h1 className='customer-title'>
          {customer.firstName} {customer.lastName}
        </h1>

        {customer.email ? (
          <a
            className='customer-subtitle'
            href={'mailto:' + customer.email}
          >
            {customer.email}
          </a>
        ) : (
          <p className='customer-subtitle'>NEED EMAIL</p>
        )}

        <div className='customer-details-wrapper'>
          <div className='details-header'>
            <p className='header-title'>Details</p>

            <button
              className='btn-link'
              onClick={() => setIsEditOpen(true)}
            >
              Edit
            </button>
          </div>

          <div className='details-body'>
            <div className='details-content'>
              <p className='details-title'>Customer ID</p>

              <button
                className='details-text-copy'
                onClick={onCopyCustomerId}
              >
                Copy me
              </button>
            </div>

            {customer.company && (
              <div className='details-content'>
                <p className='details-title'>Company</p>

                <p className='details-text'>{customer.company}</p>
              </div>
            )}

            <div className='details-content'>
              <p className='details-title'>Primary Phone</p>

              {customer.phoneNumber ? (
                <a
                  className='details-text'
                  href={'tel:' + customer.phoneNumber ?? ''}
                >
                  {prettyPhoneNumber(customer.phoneNumber ?? '')}
                </a>
              ) : (
                <p className='details-text'>-</p>
              )}
            </div>

            <div className='details-content'>
              <p className='details-title'>Primary Address</p>

              {customer.line1 ? (
                <>
                  <p className='details-text'>
                    {customer.line1}
                    {customer.line2 ? ` ${customer.line2}` : ''}
                  </p>

                  <p className='details-text'>
                    {customer.city}, {customer.stateAbbr}{' '}
                    {customer.zip}
                  </p>
                </>
              ) : (
                <p className='details-text'>-</p>
              )}
            </div>
          </div>

          <div className='details-footer'>
            <button
              className='details-button-link'
              onClick={onOpenAltDetails}
            >
              Alternative details
              <img
                src={rightArrowIcon}
                alt='arrow right'
                className='link-icon'
              />
            </button>
          </div>
        </div>
      </div>

      <AltDetailsDrawer
        isOpen={isAltOpen}
        setIsOpen={setIsAltOpen}
        customer={customer}
      />
    </>
  );
}
