import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import customerService from 'services/customerService';
import { useNavigate } from 'react-router-dom';

export default function TopbarSearch() {
  const fetchRef = useRef(0);
  const navigate = useNavigate();

  const [fetchedCustomers, setFetchedCustomers] = useState<
    SelectProps['options']
  >([]);

  const [isFetching, setIsFetching] = useState(false);

  const debounceFetcher = useMemo(() => {
    const debounceTimeout = 800;

    const loadOptions = (value: string) => {
      if (value === '') return;

      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setFetchedCustomers([]);
      setIsFetching(true);

      customerService.searchCustomers(value).then((customers) => {
        if (fetchId !== fetchRef.current) {
          setIsFetching(false);
          return;
        }

        setFetchedCustomers(
          customers.map((c) => ({
            label: c?.email,
            value: c?.specialId,
          }))
        );

        setIsFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, []);

  const onSelect = (value: string, option: DefaultOptionType) => {
    navigate(`/customers/${value}`);
  };

  return (
    <div className='topbar-search-wrapper'>
      <Select
        showSearch
        onSelect={onSelect}
        onSearch={debounceFetcher}
        options={fetchedCustomers}
        filterOption={false}
        placeholder='Search customers...'
        className='search-input'
        defaultActiveFirstOption={false}
        loading={isFetching}
      />
    </div>
  );
}
