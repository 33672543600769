import { Modal } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import {
  InputField,
  MaskField,
  MultiInputField,
} from 'components/Fields';
import Loader from 'components/core/Loader';
import { CustomerAddress } from 'models/customer';
import { useEffect, useState } from 'react';
import addressService from 'services/addressService';

interface Props {
  existingAddress?: CustomerAddress;
  customerId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onMutated: () => void;
}

type CreateUpdateAddress = Pick<
  Partial<CustomerAddress>,
  'line1' | 'line2' | 'city' | 'stateAbbr' | 'zip' | 'note'
>;

const INIT_ADDRESS: CreateUpdateAddress = {
  line1: '',
  line2: '',
  city: '',
  stateAbbr: 'MI',
  zip: '',
  note: '',
};

export default function CreateUpdateModal({
  existingAddress,
  customerId,
  isOpen,
  setIsOpen,
  onMutated,
}: Props) {
  const [messageApi, contextHolder] = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const INIT_FORM = existingAddress
    ? {
        line1: existingAddress.line1,
        line2: existingAddress.line2,
        city: existingAddress.city,
        stateAbbr: existingAddress.stateAbbr,
        zip: existingAddress.zip,
        note: existingAddress.note,
      }
    : INIT_ADDRESS;

  const [addressForm, setAddressForm] =
    useState<CreateUpdateAddress>(INIT_FORM);

  useEffect(() => {
    setAddressForm(INIT_FORM);
  }, [existingAddress]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (existingAddress) {
        // Updating address
        await addressService.updateAddress({
          id: existingAddress.id,
          customerId,
          ...addressForm,
        });

        messageApi.open({
          type: 'success',
          content: 'Address updated!',
        });
      } else {
        // Creating new address
        await addressService.createAddress({
          customerId,
          ...addressForm,
        });

        messageApi.open({
          type: 'success',
          content: 'New address created!',
        });

        setAddressForm(INIT_ADDRESS);
      }

      onMutated();

      setIsOpen(false);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      messageApi.open({
        type: 'error',
        content: (error as Error).message,
      });
    }
  };

  const onClose = () => {
    if (!existingAddress) {
      setAddressForm(INIT_ADDRESS);
    }

    setIsOpen(false);
  };

  const isCreateDisabled = () => {
    const requiredKeys = ['line1', 'city', 'stateAbbr', 'zip'];

    const isAddressIncomplete =
      Object.entries(addressForm).some(
        ([key, val]) => requiredKeys.includes(key) && val === ''
      ) || addressForm.zip?.length !== 5;

    return isAddressIncomplete;
  };

  return (
    <>
      {contextHolder}

      <Modal
        title={existingAddress ? 'Update address' : 'Create address'}
        open={isOpen}
        closeIcon={null}
        closable={false}
        footer={null}
        width={450}
        className='app-modal'
        destroyOnClose
      >
        {isLoading ? (
          <Loader customClass='modal-loader' />
        ) : (
          <form className='modal-form'>
            <div className='modal-body'>
              <div className='form-fields-group'>
                <InputField
                  label='Address'
                  type='text'
                  name='line1'
                  value={addressForm.line1 ?? ''}
                  placeholder='Address line 1'
                  onChange={(value) =>
                    setAddressForm({
                      ...addressForm,
                      line1: value,
                    })
                  }
                />

                <InputField
                  type='text'
                  name='line2'
                  value={addressForm.line2 ?? ''}
                  placeholder='Address line 2'
                  onChange={(value) =>
                    setAddressForm({
                      ...addressForm,
                      line2: value,
                    })
                  }
                />

                <InputField
                  type='text'
                  name='city'
                  value={addressForm.city ?? ''}
                  placeholder='City'
                  onChange={(value) =>
                    setAddressForm({
                      ...addressForm,
                      city: value,
                    })
                  }
                />

                <InputField
                  type='text'
                  name='stateAbbr'
                  value={addressForm.stateAbbr ?? 'MI'}
                  placeholder='State'
                  onChange={(value) =>
                    setAddressForm({
                      ...addressForm,
                      stateAbbr: value.toUpperCase(),
                    })
                  }
                  disabled
                />

                <MaskField
                  type='text'
                  name='zip'
                  value={addressForm.zip ?? ''}
                  placeholder='Zipcode'
                  onChange={(value) =>
                    setAddressForm({
                      ...addressForm,
                      zip: value,
                    })
                  }
                  mask='_____'
                />
              </div>

              <div className='form-fields-group'>
                <MultiInputField
                  label='Notes'
                  name='note'
                  value={addressForm.note ?? ''}
                  placeholder='Notes'
                  onChange={(value) =>
                    setAddressForm({
                      ...addressForm,
                      note: value,
                    })
                  }
                />
              </div>
            </div>

            <div className='modal-footer'>
              <button
                className='btn-secondary'
                onClick={onClose}
                type='button'
              >
                Cancel
              </button>

              <button
                className='btn-primary'
                onClick={onSubmit}
                disabled={isCreateDisabled()}
                type='submit'
              >
                {existingAddress ? 'Update' : 'Create'}
              </button>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
}
