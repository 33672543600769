import { format } from 'date-fns';

import editIcon from 'assets/svg/icons/edit-icon.svg';

import TablePagination from 'components/core/TablePagination';

import { toCapitalize } from 'lib/capitalize';

import { File, FilesByPage } from 'models/file';
import { Customer } from 'models/customer';
import { useState } from 'react';
import EditModal from './EditModal';

interface Props {
  filesByPage?: FilesByPage;
  files: File[];
  showPagination?: boolean;
  showCustomer?: boolean;
  onSelectNewPage?: (newPage: number) => void;
  customer?: Customer;
  onCompleteEdit?: () => void;
}

export default function FilesTable({
  filesByPage,
  files,
  showPagination = false,
  showCustomer = false,
  onSelectNewPage,
  customer,
  onCompleteEdit,
}: Props) {
  const headers = showCustomer
    ? ['Name', 'Type', 'Email', 'Date created', '']
    : ['Name', 'Address', 'Date created', ''];

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(
    undefined
  );

  const onOpenDocument = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const getRowFor = (file: File) => {
    if (showCustomer) {
      return [
        file.name,
        toCapitalize(file.fileType),
        file.email,
        format(file.createdAt, "MM/dd/yyyy 'at' h:mm a"),
      ];
    } else {
      const { name, address } = file;

      return [
        name,
        address
          ? `${address.line1}${
              address.line2 ? ' ' + address.line2 : ''
            }, ${address.city} ${address.stateAbbr} ${address.zip}`
          : '-',
        format(file.createdAt, 'MM/dd/yyyy'),
      ];
    }
  };

  const onEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    file: File
  ) => {
    e.stopPropagation();

    setSelectedFile(file);

    setIsEditOpen(true);
  };

  return (
    <div className='files-table-wrapper'>
      <table className='files-table'>
        <thead className='table-header'>
          <tr className='table-row'>
            {headers.map((header, index) => (
              <th key={index} className='header-text'>
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className='table-body'>
          {files.map((file, index) => (
            <tr
              key={index}
              className='table-row'
              onClick={() => onOpenDocument(file.url)}
            >
              {getRowFor(file).map((text, jIndex) => (
                <td
                  key={jIndex}
                  className={
                    'table-text' +
                    (jIndex === 0 ? ' text-bolden' : '')
                  }
                >
                  {text}
                </td>
              ))}

              {!showCustomer ? (
                <td className='table-icons'>
                  <button
                    className='icon-btn'
                    onClick={(e) => onEdit(e, file)}
                  >
                    <img
                      src={editIcon}
                      alt='edit icon'
                      className='icon'
                    />
                  </button>
                </td>
              ) : (
                <td className='table-icons'></td>
              )}
            </tr>
          ))}

          {files.length === 0 && (
            <tr className='table-row not-selectable'>
              <td
                className='table-text text-center'
                colSpan={showCustomer ? 5 : 4}
              >
                There are no files here yet...
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showPagination && onSelectNewPage && (
        <TablePagination
          page={filesByPage?.currentPage ?? 0}
          totalItems={filesByPage?.totalFiles ?? 0}
          itemsPerPage={filesByPage?.filesPerPage ?? 0}
          onSelect={onSelectNewPage}
        />
      )}

      {customer && selectedFile && (
        <EditModal
          existingCustomer={customer}
          existingFile={selectedFile}
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          onComplete={onCompleteEdit ? onCompleteEdit : () => {}}
        />
      )}
    </div>
  );
}
