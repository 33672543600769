import Customers from 'pages/Customers';
import Files from 'pages/Files';
import Login from 'pages/Login';
import SingleCustomer from 'pages/SingleCustomer';

export const ROUTE_TYPE: { [key: string]: string } = {
  AUTH: 'AUTH',
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export interface RouteModel {
  component: any;
  type: string;
  path: string;
}

export const ROUTES: RouteModel[] = [
  {
    component: Customers,
    type: ROUTE_TYPE.PRIVATE,
    path: '',
  },
  {
    component: Customers,
    type: ROUTE_TYPE.PRIVATE,
    path: 'customers',
  },
  {
    component: SingleCustomer,
    type: ROUTE_TYPE.PRIVATE,
    path: 'customers/:specialId',
  },
  {
    component: Files,
    type: ROUTE_TYPE.PRIVATE,
    path: 'files',
  },
  {
    component: Login,
    type: ROUTE_TYPE.AUTH,
    path: 'login',
  },
];
