import { Modal } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { InputField, MaskField } from 'components/Fields';
import Loader from 'components/core/Loader';
import { formatPhoneNumber, prettyPhoneNumber } from 'lib/phone';
import { Customer } from 'models/customer';
import { useEffect, useState } from 'react';
import customerService from 'services/customerService';

interface Props {
  existingCustomer?: Customer;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onNext: (specialId: string) => void;
}

type CreateUpdateCustomer = Pick<
  Partial<Customer>,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phoneNumber'
  | 'company'
  | 'line1'
  | 'line2'
  | 'city'
  | 'stateAbbr'
  | 'zip'
>;

const INIT_CUSTOMER: CreateUpdateCustomer = {
  firstName: '',
  lastName: '',
  email: '',
  stateAbbr: 'MI',
};

export default function CreateUpdateModal({
  existingCustomer,
  isOpen,
  setIsOpen,
  onNext,
}: Props) {
  const [messageApi, contextHolder] = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const INIT_FORM = existingCustomer
    ? {
        firstName: existingCustomer.firstName ?? '',
        lastName: existingCustomer.lastName ?? '',
        email: existingCustomer.email ?? '',
        phoneNumber: existingCustomer.phoneNumber
          ? prettyPhoneNumber(existingCustomer.phoneNumber)
          : undefined,
        company: existingCustomer.company ?? '',

        line1: existingCustomer.line1 ?? '',
        line2: existingCustomer.line2 ?? '',
        city: existingCustomer.city ?? '',
        stateAbbr: existingCustomer.stateAbbr ?? '',
        zip: existingCustomer.zip,
      }
    : INIT_CUSTOMER;

  const [customerForm, setCustomerForm] =
    useState<CreateUpdateCustomer>(INIT_FORM);

  useEffect(() => {
    setCustomerForm(INIT_FORM);
  }, [existingCustomer]);

  const onCreate = async () => {
    try {
      setIsLoading(true);

      let specialId = '';

      const { phoneNumber, zip, ...restOfCustomer } = customerForm;

      if (existingCustomer) {
        // Updating customer
        const updatedCustomer = await customerService.updateCustomer({
          id: existingCustomer.id,
          ...restOfCustomer,
          zip: zip ?? undefined,
          phoneNumber: phoneNumber
            ? formatPhoneNumber(phoneNumber)
            : undefined,
        });

        specialId = updatedCustomer.specialId;

        messageApi.open({
          type: 'success',
          content: 'Customer updated!',
        });
      } else {
        // Creating new customer
        const newCustomer = await customerService.createCustomer({
          ...restOfCustomer,
          zip: zip ?? undefined,
          phoneNumber: phoneNumber
            ? formatPhoneNumber(phoneNumber)
            : undefined,
        });

        specialId = newCustomer.specialId;

        messageApi.open({
          type: 'success',
          content: 'New customer created!',
        });

        setCustomerForm(INIT_CUSTOMER);
      }

      setIsOpen(false);
      onNext(specialId);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      messageApi.open({
        type: 'error',
        content: (error as Error).message,
      });
    }
  };

  const onClose = () => {
    if (!existingCustomer) {
      setCustomerForm(INIT_CUSTOMER);
    }

    setIsOpen(false);
  };

  const isCreateDisabled = () => {
    const requiredKeys = ['firstName'];

    const isCustomerIncomplete =
      Object.entries(customerForm).some(
        ([key, val]) => requiredKeys.includes(key) && val === ''
      ) ||
      (customerForm.phoneNumber
        ? formatPhoneNumber(customerForm.phoneNumber ?? '').length !==
          10
        : false);

    return isCustomerIncomplete;
  };

  return (
    <>
      {contextHolder}

      <Modal
        title={
          existingCustomer ? 'Update customer' : 'Create customer'
        }
        open={isOpen}
        closeIcon={null}
        closable={false}
        footer={null}
        width={450}
        className='app-modal'
        destroyOnClose
      >
        {isLoading ? (
          <Loader customClass='modal-loader' />
        ) : (
          <form className='modal-form'>
            <div className='modal-body'>
              <div className='form-fields-flex'>
                <InputField
                  label='First name'
                  type='text'
                  name='firstName'
                  value={customerForm.firstName ?? ''}
                  placeholder='First name'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      firstName: value,
                    })
                  }
                />

                <InputField
                  label='Last name'
                  type='text'
                  name='lastName'
                  value={customerForm.lastName ?? ''}
                  placeholder='Last name'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      lastName: value,
                    })
                  }
                />
              </div>

              <div className='form-fields-group'>
                <InputField
                  label='Company'
                  type='text'
                  name='company'
                  value={customerForm.company ?? ''}
                  placeholder='Company'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      company: value,
                    })
                  }
                />
              </div>

              <div className='form-fields-group'>
                <InputField
                  label='Email'
                  type='email'
                  name='email'
                  value={customerForm.email ?? ''}
                  placeholder='Email address'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      email: value.toLowerCase(),
                    })
                  }
                />
              </div>

              <div className='form-fields-group'>
                <MaskField
                  label='Phone number'
                  type='text'
                  name='phone'
                  value={customerForm.phoneNumber ?? ''}
                  placeholder='Phone number'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      phoneNumber: value,
                    })
                  }
                  mask='(___) ___-____'
                />
              </div>

              <div className='form-fields-group'>
                <InputField
                  label='Address'
                  type='text'
                  name='line1'
                  value={customerForm.line1 ?? ''}
                  placeholder='Address line 1'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      line1: value,
                    })
                  }
                />

                <InputField
                  type='text'
                  name='line2'
                  value={customerForm.line2 ?? ''}
                  placeholder='Address line 2'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      line2: value,
                    })
                  }
                />

                <InputField
                  type='text'
                  name='city'
                  value={customerForm.city ?? ''}
                  placeholder='City'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      city: value,
                    })
                  }
                />

                <InputField
                  type='text'
                  name='stateAbbr'
                  value={customerForm.stateAbbr ?? 'MI'}
                  placeholder='State'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      stateAbbr: value.toUpperCase(),
                    })
                  }
                  disabled
                />

                <MaskField
                  type='text'
                  name='zip'
                  value={customerForm.zip ?? ''}
                  placeholder='Zipcode'
                  onChange={(value) =>
                    setCustomerForm({
                      ...customerForm,
                      zip: value,
                    })
                  }
                  mask='_____'
                />
              </div>
            </div>

            <div className='modal-footer'>
              <button
                className='btn-secondary'
                onClick={onClose}
                type='button'
              >
                Cancel
              </button>

              <button
                className='btn-primary'
                onClick={onCreate}
                disabled={isCreateDisabled()}
                type='submit'
              >
                {existingCustomer ? 'Update' : 'Create'}
              </button>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
}
