import { Modal, Select, SelectProps } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { SelectField } from 'components/Fields';
import Loader from 'components/core/Loader';

import { Customer } from 'models/customer';
import { File, FileType } from 'models/file';
import { useEffect, useState } from 'react';
import addressService from 'services/addressService';
import fileService from 'services/fileService';

interface Props {
  existingFile: File;
  existingCustomer: Customer;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onComplete: () => void;
}

type UploadFile = {
  customerSpecialId: string;
  customerInfo: string;
  addressId?: number;
  addressInfo?: string;
  fileType: FileType;
};

const INIT_FILE: UploadFile = {
  customerSpecialId: '',
  customerInfo: '',
  fileType: 'PROPOSAL',
};

interface SelectOption {
  label: string;
  value: string;
}

const typeOptions: SelectOption[] = [
  {
    value: 'PROPOSAL',
    label: 'Proposal',
  },
  { value: 'INVOICE', label: 'Invoice' },
  { value: 'PICTURE', label: 'Picture' },
  { value: 'OTHER', label: 'Other' },
];

// TODO: Better around this and upload
export default function EditModal({
  existingFile,
  existingCustomer,
  isOpen,
  setIsOpen,
  onComplete,
}: Props) {
  const [messageApi, contextHolder] = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const [fetchedAddresses, setFetchedAddresses] = useState<
    SelectProps['options']
  >([]);

  const [fileForm, setFileForm] = useState<UploadFile>(INIT_FILE);

  const isCreateDisabled = fileForm.customerSpecialId === '';

  useEffect(() => {
    const { address } = existingFile;

    setFileForm({
      customerSpecialId: existingCustomer.specialId,
      customerInfo: `${existingCustomer.firstName} ${existingCustomer.lastName}`,
      addressId: address?.id ?? existingFile.addressId ?? -1,
      addressInfo: address
        ? `${address.line1}${
            address.line2 ? ' ' + address.line2 : ''
          }, ${address.city} ${address.stateAbbr} ${address.zip}`
        : '',
      fileType: existingFile.fileType,
    });
  }, [existingCustomer, existingFile]);

  useEffect(() => {
    const getAddresses = async () => {
      const addresses =
        await addressService.retrieveAddressesByCustomer(
          existingCustomer.specialId
        );

      setFetchedAddresses(
        addresses.map((addr) => ({
          label: `${addr.line1}${
            addr.line2 ? ' ' + addr.line2 : ''
          }, ${addr.city} ${addr.stateAbbr} ${addr.zip}`,
          value: addr.id,
        }))
      );
    };

    getAddresses();
  }, [existingCustomer]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const { fileType, addressId } = fileForm;

      await fileService.updateFile({
        id: existingFile.id,
        fileType,
        addressId: addressId ?? -1,
      });

      messageApi.open({
        type: 'success',
        content: 'Successfully updated file!',
      });

      setFileForm(INIT_FILE);

      setIsOpen(false);
      onComplete();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      messageApi.open({
        type: 'error',
        content: (error as Error).message,
      });
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {contextHolder}

      <Modal
        title='Edit file'
        open={isOpen}
        closeIcon={null}
        closable={false}
        footer={null}
        width={450}
        className='app-modal'
        destroyOnClose
      >
        {isLoading ? (
          <Loader customClass='modal-loader' />
        ) : (
          <form className='modal-form'>
            <div className='modal-body'>
              <div className='form-fields-group'>
                <p className='modal-text sml-text'>
                  Editing file: {existingFile.name}
                </p>
              </div>

              {fileForm.customerSpecialId !== '' && (
                <div className='form-fields-group'>
                  <div className='input-field-wrapper'>
                    <p className='input-label'>
                      Select address (optional)
                    </p>

                    <Select
                      value={
                        fileForm.addressInfo !== ''
                          ? fileForm.addressInfo
                          : null
                      }
                      onSelect={(_, option) =>
                        setFileForm({
                          ...fileForm,
                          addressId: parseInt(
                            (option as SelectOption).value
                          ),
                          addressInfo: (option as SelectOption).label,
                        })
                      }
                      options={fetchedAddresses}
                      placeholder='Select address'
                      className='input-field-full'
                      allowClear
                      onClear={() =>
                        setFileForm({
                          ...fileForm,
                          addressId: undefined,
                          addressInfo: undefined,
                        })
                      }
                    />
                  </div>
                </div>
              )}

              <div className='form-fields-group'>
                <SelectField
                  label='Select file(s) type'
                  value={fileForm.fileType}
                  onChange={(value) =>
                    setFileForm({
                      ...fileForm,
                      fileType: value as FileType,
                    })
                  }
                  options={typeOptions}
                  placeholder='File type'
                />
              </div>
            </div>

            <div className='modal-footer'>
              <button
                className='btn-secondary'
                onClick={onClose}
                type='button'
              >
                Cancel
              </button>

              <button
                className='btn-primary'
                onClick={onSubmit}
                disabled={isCreateDisabled}
                type='button'
              >
                Update
              </button>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
}
