import { useState } from 'react';

import ImperialLogo from 'assets/images/logo.png';

import Layout from 'components/core/Layout';
import Loader from 'components/core/Loader';
import { InputField } from 'components/Fields';

import errorService from 'services/errorService';
import authenticationService from 'services/authenticationService';

export default function Login() {
  const INIT_FORM = {
    email: '',
    password: '',
  };

  const [loading, setLoading] = useState(false);
  const [loginForm, setLoginForm] = useState(INIT_FORM);

  const onLogin = async () => {
    try {
      const { email, password } = loginForm;
      setLoading(true);

      await authenticationService.login(email, password);
    } catch (error) {
      errorService.reportError(error as Error);
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <Layout seoTitle='Login - Imperial Building' seoDescription=''>
      <section className='auth-content-section'>
        <div className='auth-content'>
          <img
            src={ImperialLogo}
            alt='Imperial logo'
            className='auth-icon'
          />

          <h2 className='auth-content-title'>Login</h2>

          <p className='auth-content-subtitle'>
            Welcome to Imperial Building!
          </p>

          <form className='auth-form'>
            <InputField
              type='email'
              name='email'
              value={loginForm.email}
              placeholder='Enter email'
              onChange={(value) =>
                setLoginForm({ ...loginForm, email: value })
              }
            />

            <InputField
              type='password'
              name='password'
              value={loginForm.password}
              placeholder='Enter password'
              onChange={(value) =>
                setLoginForm({ ...loginForm, password: value })
              }
            />

            <button
              onClick={onLogin}
              disabled={
                loginForm.email === '' || loginForm.password === ''
              }
              className='auth-form-btn'
            >
              Login
            </button>
          </form>
        </div>
      </section>
    </Layout>
  );
}
