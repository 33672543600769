import { Modal } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { MaskField, MultiInputField } from 'components/Fields';
import Loader from 'components/core/Loader';
import { formatPhoneNumber, prettyPhoneNumber } from 'lib/phone';
import { CustomerPhone } from 'models/customer';
import { useEffect, useState } from 'react';
import phoneService from 'services/phoneService';

interface Props {
  existingPhone?: CustomerPhone;
  customerId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onMutated: () => void;
}

type CreateUpdateAddress = Pick<
  Partial<CustomerPhone>,
  'phoneNumber' | 'note'
>;

const INIT_PHONE: CreateUpdateAddress = {
  phoneNumber: '',
  note: '',
};

export default function CreateUpdateModal({
  existingPhone,
  customerId,
  isOpen,
  setIsOpen,
  onMutated,
}: Props) {
  const [messageApi, contextHolder] = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const INIT_FORM = existingPhone
    ? {
        phoneNumber: existingPhone.phoneNumber,
        note: existingPhone.note,
      }
    : INIT_PHONE;

  const [phoneForm, setPhoneForm] =
    useState<CreateUpdateAddress>(INIT_FORM);

  useEffect(() => {
    setPhoneForm(INIT_FORM);
  }, [existingPhone]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (existingPhone) {
        // Updating phone
        await phoneService.updatePhone({
          id: existingPhone.id,
          customerId,
          ...phoneForm,
        });

        messageApi.open({
          type: 'success',
          content: 'Phone updated!',
        });
      } else {
        // Creating new phone
        await phoneService.createPhone({
          customerId,
          ...phoneForm,
        });

        messageApi.open({
          type: 'success',
          content: 'New phone created!',
        });

        setPhoneForm(INIT_PHONE);
      }

      onMutated();

      setIsOpen(false);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      messageApi.open({
        type: 'error',
        content: (error as Error).message,
      });
    }
  };

  const onClose = () => {
    if (!existingPhone) {
      setPhoneForm(INIT_PHONE);
    }

    setIsOpen(false);
  };

  const isCreateDisabled = () => {
    const requiredKeys = ['phoneNumber'];

    const isPhoneIncomplete =
      Object.entries(phoneForm).some(
        ([key, val]) => requiredKeys.includes(key) && val === ''
      ) ||
      formatPhoneNumber(phoneForm.phoneNumber ?? '').length !== 10;

    return isPhoneIncomplete;
  };

  return (
    <>
      {contextHolder}

      <Modal
        title={existingPhone ? 'Update phone' : 'Create phone'}
        open={isOpen}
        closeIcon={null}
        closable={false}
        footer={null}
        width={450}
        className='app-modal'
        destroyOnClose
      >
        {isLoading ? (
          <Loader customClass='modal-loader' />
        ) : (
          <form className='modal-form'>
            <div className='modal-body'>
              <div className='form-fields-group'>
                <MaskField
                  label='Phone number'
                  type='text'
                  name='phone'
                  value={prettyPhoneNumber(
                    phoneForm.phoneNumber ?? ''
                  )}
                  placeholder='Phone number'
                  onChange={(value) =>
                    setPhoneForm({
                      ...phoneForm,
                      phoneNumber: formatPhoneNumber(value),
                    })
                  }
                  mask='(___) ___-____'
                />
              </div>

              <div className='form-fields-group'>
                <MultiInputField
                  label='Notes'
                  name='note'
                  value={phoneForm.note ?? ''}
                  placeholder='Notes'
                  onChange={(value) =>
                    setPhoneForm({
                      ...phoneForm,
                      note: value,
                    })
                  }
                />
              </div>
            </div>

            <div className='modal-footer'>
              <button
                className='btn-secondary'
                onClick={onClose}
                type='button'
              >
                Cancel
              </button>

              <button
                className='btn-primary'
                onClick={onSubmit}
                disabled={isCreateDisabled()}
                type='submit'
              >
                {existingPhone ? 'Update' : 'Create'}
              </button>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
}
