import { Drawer } from 'antd';
import { RetrievedCustomer } from 'models/customer';
import { useState } from 'react';
import AddressesBlock from './alternatives/AddressesBlock';
import EmailsBlock from './alternatives/EmailsBlock';
import PhonesBlock from './alternatives/PhonesBlock';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  customer: RetrievedCustomer;
}

// TODO: Update the blocks better
export default function AltDetailsDrawer({
  isOpen,
  setIsOpen,
  customer,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Drawer
      title='Alternative details'
      onClose={() => setIsOpen(false)}
      open={isOpen}
      className='app-drawer alt-details-drawer'
      closable={!isLoading}
    >
      <div className='drawer-body'>
        <AddressesBlock
          setLoading={setIsLoading}
          customer={customer}
        />
        <PhonesBlock setLoading={setIsLoading} customer={customer} />
        <EmailsBlock setLoading={setIsLoading} customer={customer} />
      </div>
    </Drawer>
  );
}
