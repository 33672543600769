import addIcon from 'assets/svg/icons/add-icon.svg';
import { useGetCustomer } from 'lib/useCustomer';
import { CustomerAddress, RetrievedCustomer } from 'models/customer';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import addressService from 'services/addressService';
import CreateUpdateModal from './address/CreateUpdateModal';
import useMessage from 'antd/es/message/useMessage';
import DeleteModal from './modals/DeleteModal';
import { isAddressExist } from 'lib/address';

interface Props {
  setLoading: (isLoading: boolean) => void;
  customer: RetrievedCustomer;
}

export default function AddressesBlock({
  setLoading,
  customer,
}: Props) {
  const [messageApi, contextHolder] = useMessage();
  const params = useParams<{ specialId?: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<
    CustomerAddress | undefined
  >(undefined);

  const { mutate } = useGetCustomer(params.specialId ?? '');

  const onSetPrimary = async (id: number) => {
    setLoading(true);

    await addressService.updatePrimaryAddress(id, customer.id);

    await mutate();

    setLoading(false);
  };

  const onOpenCreate = () => {
    setSelectedAddress(undefined);
    setIsOpen(true);
  };

  const onMutated = async () => {
    await mutate();
  };

  const onEdit = (addr: CustomerAddress) => {
    setSelectedAddress(addr);
    setIsOpen(true);
  };

  const onOpenDelete = (addr: CustomerAddress) => {
    setSelectedAddress(addr);
    setIsDeleteOpen(true);
  };

  const onDelete = async (id?: number) => {
    if (id) {
      await addressService.deleteAddress(id);

      await mutate();

      messageApi.open({
        type: 'success',
        content: 'Successfully deleted address!',
      });

      setSelectedAddress(undefined);
      setIsDeleteOpen(false);
    }
  };

  return (
    <div className='details-wrapper'>
      {contextHolder}

      <div className='details-header'>
        <p className='header-title'>Addresses</p>

        {isAddressExist(customer) && (
          <button className='header-btn' onClick={onOpenCreate}>
            <img src={addIcon} alt='add icon' className='btn-icon' />
          </button>
        )}
      </div>

      <div className='details-body'>
        <div className='details-block'>
          <div className='details-flex'>
            <div className='details-text-wrapper'>
              {isAddressExist(customer) ? (
                <>
                  <p className='details-text'>
                    {customer.line1}
                    {customer.line2 ? ` ${customer.line2}` : ''}
                  </p>

                  <p className='details-text'>
                    {customer.city}, {customer.stateAbbr}{' '}
                    {customer.zip}
                  </p>
                </>
              ) : (
                <p className='details-text'>NO PRIMARY ADDRESS</p>
              )}
            </div>
          </div>

          {isAddressExist(customer) && (
            <p className='details-primary-text'>Primary address</p>
          )}
        </div>

        {customer.addresses.map((addr, index) => (
          <div className='details-block' key={index}>
            <div className='details-flex'>
              <div className='details-text-wrapper'>
                <p className='details-text'>
                  {addr.line1}
                  {addr.line2 ? ` ${addr.line2}` : ''}
                </p>

                <p className='details-text'>
                  {addr.city}, {addr.stateAbbr} {addr.zip}
                </p>
              </div>

              <div className='details-btn-wrapper'>
                <button
                  className='btn-link'
                  onClick={() => onEdit(addr)}
                >
                  Edit
                </button>

                <button
                  className='btn-link text-red'
                  onClick={() => onOpenDelete(addr)}
                >
                  Delete
                </button>
              </div>
            </div>

            {addr.note && (
              <p className='details-note-text'>
                <span>Notes:</span>
                <br />
                {addr.note}
              </p>
            )}

            {/* TODO: Add address to data and rm from customer */}
            {/* <button
              className='btn-link'
              onClick={() => onSetPrimary(addr.id)}
            >
              Set primary address
            </button> */}
          </div>
        ))}
      </div>

      <CreateUpdateModal
        existingAddress={selectedAddress}
        customerId={customer.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onMutated={onMutated}
      />

      <DeleteModal
        id={selectedAddress?.id}
        question='Are you sure you would like to delete this address?'
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onDelete={onDelete}
      />
    </div>
  );
}
