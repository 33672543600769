/**
 * @function formatPhone
 * @description This function formats the phone to contain 10 digits in a string
 * @access public
 *
 * @param {string} phone Any kind of phone that can be in any format
 *
 * @return {string} Returns a formatted phone number as 10 characters
 */
export const formatPhoneNumber = (phone: string): string => {
  const number = phone.replace(/\D/g, ''); // Removes all non-int characters

  if (number.length === 10) {
    return number;
  } else if (number.length === 11 && number[0] === '1') {
    return number.substring(1);
  } else {
    return phone;
  }
};

export const prettyPhoneNumber = (phone: string) => {
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match
    ? '(' + match[1] + ') ' + match[2] + '-' + match[3]
    : phone;
};
