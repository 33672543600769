import TablePagination from 'components/core/TablePagination';
import { prettyPhoneNumber } from 'lib/phone';
import { CustomersByPage } from 'models/customer';

interface Props {
  onSelectCustomer: (specialId: string) => void;
  customersByPage: CustomersByPage;
  onSelectNewPage: (newPage: number) => void;
}

export default function CustomersTable({
  onSelectCustomer,
  customersByPage,
  onSelectNewPage,
}: Props) {
  return (
    <div className='customers-table-wrapper'>
      <table className='customers-table'>
        <thead className='table-header'>
          <tr className='table-row'>
            {['Name', 'Phone', 'Email', 'Address'].map(
              (header, index) => (
                <th key={index} className='header-text'>
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>

        <tbody className='table-body'>
          {customersByPage.customers.map((customer, index) => (
            <tr
              key={index}
              className='table-row'
              onClick={() => onSelectCustomer(customer.specialId)}
            >
              {[
                `${customer.lastName}, ${customer.firstName}`,
                prettyPhoneNumber(customer.phoneNumber ?? ''),
                customer.email ?? '',
                customer.line1 ? customer.line1 : '',
              ].map((text, jIndex) => (
                <td
                  key={jIndex}
                  className={
                    'table-text' +
                    (jIndex === 0 ? ' text-bolden' : '')
                  }
                >
                  {text !== '' ? text : '-'}
                </td>
              ))}
            </tr>
          ))}

          {customersByPage.customers.length === 0 && (
            <tr className='table-row not-selectable'>
              <td className='table-text text-center' colSpan={4}>
                There are no customers here yet...
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <TablePagination
        page={customersByPage.currentPage}
        totalItems={customersByPage.totalCustomers}
        itemsPerPage={customersByPage.customersPerPage}
        onSelect={onSelectNewPage}
      />
    </div>
  );
}
