import { Customer } from 'models/customer';

export const isAddressExist = (customer: Customer) => {
  return (
    customer.line1 &&
    customer.line1 !== '' &&
    customer.city &&
    customer.city !== '' &&
    customer.stateAbbr &&
    customer.stateAbbr !== '' &&
    customer.zip &&
    customer.zip !== ''
  );
};
