import { InboxOutlined } from '@ant-design/icons';
import { useMask } from '@react-input/mask';
import { Select, SelectProps, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';

const { Dragger } = Upload;

type InputFieldProps = {
  label?: string;
  type: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

type MaskFieldProps = InputFieldProps & {
  mask: string;
};

type SelectFieldProps = Omit<InputFieldProps, 'type' | 'name'> & {
  options: SelectProps['options'];
};

type UploadFieldProps = {
  label?: string;
  name: string;
  accept: string;
  onChange: (list: RcFile[]) => void;
};

export const InputField = ({
  label,
  type,
  name,
  value,
  placeholder,
  onChange,
  disabled = false,
}: InputFieldProps) => (
  <div className='input-field-wrapper'>
    {label && <p className='input-label'>{label}</p>}

    <input
      type={type}
      name={name}
      defaultValue={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      className='input-field'
      disabled={disabled}
    />
  </div>
);

export const MultiInputField = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  disabled = false,
}: Omit<InputFieldProps, 'type'>) => (
  <div className='input-field-wrapper'>
    {label && <p className='input-label'>{label}</p>}

    <textarea
      name={name}
      defaultValue={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      className='input-field'
      disabled={disabled}
      rows={3}
    />
  </div>
);

export const MaskField = ({
  label,
  type,
  name,
  value,
  placeholder,
  onChange,
  mask,
}: MaskFieldProps) => {
  const inputRef = useMask({
    mask,
    replacement: { _: /\d/ },
  });

  return (
    <div className='input-field-wrapper'>
      {label && <p className='input-label'>{label}</p>}

      <input
        type={type}
        name={name}
        defaultValue={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        className='input-field'
        ref={inputRef}
      />
    </div>
  );
};

export const SelectField = ({
  label,
  value,
  placeholder,
  onChange,
  options,
}: SelectFieldProps) => (
  <div className='input-field-wrapper'>
    {label && <p className='input-label'>{label}</p>}

    <Select
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      className='input-field-full'
    />
  </div>
);

export const UploadField = ({
  label,
  name,
  accept,
  onChange,
}: UploadFieldProps) => (
  <div className='input-field-wrapper'>
    {label && <p className='input-label'>{label}</p>}

    <Dragger
      name={name}
      beforeUpload={(_, list) => {
        onChange(list);

        return false;
      }}
      multiple
      accept={accept}
      showUploadList={false}
    >
      <p className='ant-upload-drag-icon'>
        <InboxOutlined style={{ color: '#3059b0' }} />
      </p>

      <p className='ant-upload-text'>Select here or drop file(s)</p>
    </Dragger>
  </div>
);
