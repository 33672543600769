import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from 'firebase/auth';
import errorService from './errorService';
import { FirebaseError } from 'firebase/app';
import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

export const createApiClient = async () => {
  const identityToken = await getAuth().currentUser!.getIdToken();

  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: identityToken,
    },
  });

  return instance;
};

const login = async (email: string, password: string) => {
  try {
    const auth = getAuth();

    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    throw new Error(
      errorService.convertFirebaseReadableError(
        error as FirebaseError
      )
    );
  }
};

const logout = async () => {
  try {
    const auth = getAuth();

    await signOut(auth);
  } catch (error) {
    throw new Error(
      errorService.convertFirebaseReadableError(
        error as FirebaseError
      )
    );
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    const auth = getAuth();

    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw new Error(
      errorService.convertFirebaseReadableError(
        error as FirebaseError
      )
    );
  }
};

export const resetPassword = async (newPassword: string) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      await updatePassword(user, newPassword);
      return 'Success';
    } else {
      throw new Error('Error occurred');
    }
  } catch (error) {
    throw new Error(
      errorService.convertFirebaseReadableError(
        error as FirebaseError
      )
    );
  }
};

export default {
  login,
  logout,
  sendPasswordReset,
  resetPassword,
};
