export interface NavItem {
  title: string;
  path: string;
  acceptedPaths: string[];
}

export const navItems: NavItem[] = [
  {
    title: 'Customers',
    path: '/customers',
    acceptedPaths: ['', 'customers'],
  },
  {
    title: 'Files',
    path: '/files',
    acceptedPaths: ['files'],
  },
];
