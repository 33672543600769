import { File, FilesByPage } from 'models/file';
import useSWR, { KeyedMutator } from 'swr';
import { fetcher } from './useFetcher';

type GetFilesByPage = {
  filesByPage: FilesByPage | undefined;
  error: any;
  isLoadingFiles: boolean;
  mutate: KeyedMutator<FilesByPage>;
};

type GetFilesByCustomer = {
  files: File[] | undefined;
  error: any;
  isLoadingFiles: boolean;
  mutate: KeyedMutator<File[]>;
};

const useGetFilesByPage = (page: number): GetFilesByPage => {
  const { data, error, isLoading, mutate } = useSWR<FilesByPage>(
    `/file?page=${page}`,
    fetcher
  );

  return {
    filesByPage: data,
    error,
    isLoadingFiles: isLoading,
    mutate,
  };
};

const useGetFilesByCustomer = (
  specialId: string
): GetFilesByCustomer => {
  const { data, error, isLoading, mutate } = useSWR<File[]>(
    `/file/customer/${specialId}`,
    fetcher
  );

  return {
    files: data,
    error,
    isLoadingFiles: isLoading,
    mutate,
  };
};

export { useGetFilesByPage, useGetFilesByCustomer };
