import {
  CustomerAddress,
  Customer,
  UnsavedCustomerAddress,
  UnsavedCustomer,
} from 'models/customer';
import { createApiClient } from './authenticationService';

const retrieveCustomers = async (): Promise<Customer[]> => {
  try {
    const client = await createApiClient();

    const { data } = await client.get('/customer');

    return data;
  } catch (error) {
    throw error;
  }
};

const retrieveCustomersByPage = async (
  page: number
): Promise<Customer[]> => {
  try {
    const client = await createApiClient();

    const { data } = await client.get('/page/' + page);

    return data;
  } catch (error) {
    throw error;
  }
};

const createCustomer = async (
  customer: Partial<UnsavedCustomer>
): Promise<Customer> => {
  try {
    const client = await createApiClient();

    const { data } = await client.post('/customer', customer);

    return data;
  } catch (error) {
    throw error;
  }
};

const updateCustomer = async (
  customer: Partial<
    Omit<Customer, 'specialId' | 'firebaseUuid' | 'country'>
  >
): Promise<Customer> => {
  try {
    const client = await createApiClient();

    const { data } = await client.patch('/customer', customer);

    return data;
  } catch (error) {
    throw error;
  }
};

const searchCustomers = async (
  content: string
): Promise<Partial<Customer[]>> => {
  try {
    const client = await createApiClient();

    const { data } = await client.get(
      `/customer/search?content=${content}`
    );

    return data as Customer[];
  } catch (error) {
    throw error;
  }
};

export default {
  retrieveCustomers,
  retrieveCustomersByPage,
  createCustomer,
  updateCustomer,
  searchCustomers,
};
