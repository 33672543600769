import { File } from 'models/file';

interface Props {
  files: File[];
  onViewAll: () => void;
}

export default function FilesCollection({ files, onViewAll }: Props) {
  const filteredFiles = files.slice(0, 4);

  return (
    <div className='files-collection-wrapper'>
      <div className='collection-body'>
        <div className='collection-row'>
          {filteredFiles.map((document, index) => (
            <div key={index} className='collection-img-col'>
              <a
                href={document.url}
                target='_blank'
                className='img-btn'
              >
                <img
                  src={document.url}
                  alt={document.name}
                  className='img'
                />
              </a>
            </div>
          ))}

          {filteredFiles.length === 0 && (
            <div className='collection-empty-col'>
              <p className='empty-text'>
                There are no photos here yet...
              </p>
            </div>
          )}
        </div>
      </div>

      {filteredFiles.length !== 0 && (
        <div className='collection-footer'>
          <button className='btn-link' onClick={onViewAll}>
            View all
          </button>
        </div>
      )}
    </div>
  );
}
