import {
  CustomerAddress,
  UnsavedCustomerAddress,
} from 'models/customer';
import { createApiClient } from './authenticationService';

const updatePrimaryAddress = async (
  addressId: number,
  customerId: number
) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/address/primary', {
      id: addressId,
      customerId,
    });
  } catch (error) {
    throw error;
  }
};

const createAddress = async (
  address: Partial<UnsavedCustomerAddress>
) => {
  try {
    const client = await createApiClient();

    await client.post('/customer/address', address);
  } catch (error) {
    throw error;
  }
};

const updateAddress = async (address: Partial<CustomerAddress>) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/address', address);
  } catch (error) {
    throw error;
  }
};

const deleteAddress = async (id: number) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/address', {
      id,
      active: false,
    });
  } catch (error) {
    throw error;
  }
};

const retrieveAddressesByCustomer = async (
  specialId: string
): Promise<CustomerAddress[]> => {
  try {
    const client = await createApiClient();

    const { data } = await client.get(
      `/customer/address/${specialId}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export default {
  updatePrimaryAddress,
  createAddress,
  updateAddress,
  deleteAddress,
  retrieveAddressesByCustomer,
};
