import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Layout from 'components/core/Layout';
import Loader from 'components/core/Loader';
import CreateUpdateModal from 'components/customers/CreateUpdateModal';
import CustomersTable from 'components/customers/CustomersTable';
import Topbar from 'components/layout/Topbar';

import { useGetCustomersByPage } from 'lib/useCustomer';

const CustomersHeader = ({
  setIsOpen,
}: {
  setIsOpen: (isOpen: boolean) => void;
}) => {
  return (
    <div className='customers-header-wrapper'>
      <h1 className='customers-title'>Customers</h1>

      <button className='btn-primary' onClick={() => setIsOpen(true)}>
        + Add customer
      </button>
    </div>
  );
};

export default function Customers() {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const fetchedPage = Number(searchParams.get('page') ?? 1);

  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(fetchedPage);

  const {
    customersByPage,
    isLoadingCustomers,
    mutate: mutateCustomers,
  } = useGetCustomersByPage(page);

  const onSelectCustomer = (specialId: string) => {
    navigate('/customers/' + specialId);
  };

  const onSelectPage = (newPage: number) => {
    navigate(`/customers?page=${newPage}`);
    setPage(newPage);
  };

  const onNewCustomer = async (specialId: string) => {
    await mutateCustomers();
  };

  if (isLoadingCustomers || !customersByPage)
    return (
      <Layout
        seoTitle='Customers - Imperial Building'
        seoDescription=''
      >
        <Topbar />

        <Loader />
      </Layout>
    );

  return (
    <Layout
      seoTitle='Customers - Imperial Building'
      seoDescription=''
    >
      <Topbar />

      <section className='customers-section'>
        <CustomersHeader setIsOpen={setIsOpen} />

        <CustomersTable
          onSelectCustomer={onSelectCustomer}
          customersByPage={customersByPage}
          onSelectNewPage={onSelectPage}
        />
      </section>

      <CreateUpdateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onNext={onNewCustomer}
      />
    </Layout>
  );
}
