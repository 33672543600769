import { notification } from 'antd';
import { FirebaseError } from 'firebase/app';

const reportError = (error: Error) => {
  notification.error({
    message: 'Error',
    description: error.message,
  });
};

const convertFirebaseReadableError = (error: FirebaseError) => {
  switch (error.code) {
    case 'auth/wrong-password': {
      return 'The provided email or password is incorrect';
    }
    case 'auth/user-not-found': {
      return 'The provided email or password is incorrect';
    }
    case 'auth/email-already-exists': {
      return 'The email already exists';
    }
    default: {
      return 'Unknown error occurred, please try again';
    }
  }
};

export default { reportError, convertFirebaseReadableError };
