import addIcon from 'assets/svg/icons/add-icon.svg';
import { prettyPhoneNumber } from 'lib/phone';
import { useGetCustomer } from 'lib/useCustomer';
import { CustomerPhone, RetrievedCustomer } from 'models/customer';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import phoneService from 'services/phoneService';
import CreateUpdateModal from './phone/CreateUpdateModal';
import DeleteModal from './modals/DeleteModal';
import useMessage from 'antd/es/message/useMessage';

interface Props {
  setLoading: (isLoading: boolean) => void;
  customer: RetrievedCustomer;
}

export default function PhonesBlock({ setLoading, customer }: Props) {
  const [messageApi, contextHolder] = useMessage();
  const params = useParams<{ specialId?: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState<
    CustomerPhone | undefined
  >(undefined);

  const { mutate } = useGetCustomer(params.specialId ?? '');

  const onSetPrimary = async (id: number) => {
    setLoading(true);

    await phoneService.updatePrimaryPhone(id, customer.id);

    await mutate();

    setLoading(false);
  };

  const onOpenCreate = () => {
    setSelectedPhone(undefined);
    setIsOpen(true);
  };

  const onMutated = async () => {
    await mutate();
  };

  const onEdit = (phone: CustomerPhone) => {
    setSelectedPhone(phone);
    setIsOpen(true);
  };

  const onOpenDelete = (phone: CustomerPhone) => {
    setSelectedPhone(phone);
    setIsDeleteOpen(true);
  };

  const onDelete = async (id?: number) => {
    if (id) {
      await phoneService.deletePhone(id);

      await mutate();

      messageApi.open({
        type: 'success',
        content: 'Successfully deleted phone number!',
      });

      setSelectedPhone(undefined);
      setIsDeleteOpen(false);
    }
  };

  return (
    <div className='details-wrapper'>
      {contextHolder}

      <div className='details-header'>
        <p className='header-title'>Phone numbers</p>

        {customer.phoneNumber && (
          <button className='header-btn' onClick={onOpenCreate}>
            <img src={addIcon} alt='add icon' className='btn-icon' />
          </button>
        )}
      </div>

      <div className='details-body'>
        <div className='details-block'>
          <div className='details-flex'>
            <div className='details-text-wrapper'>
              <p className='details-text'>
                {customer.phoneNumber
                  ? prettyPhoneNumber(customer.phoneNumber)
                  : 'NO PRIMARY PHONE NUMBER'}
              </p>
            </div>
          </div>

          {customer.phoneNumber && (
            <p className='details-primary-text'>
              Primary phone number
            </p>
          )}
        </div>

        {customer.phones.map((phone, index) => (
          <div className='details-block' key={index}>
            <div className='details-flex'>
              <div className='details-text-wrapper'>
                <p className='details-text'>
                  {prettyPhoneNumber(phone.phoneNumber)}
                </p>
              </div>
              <div className='details-btn-wrapper'>
                <button
                  className='btn-link'
                  onClick={() => onEdit(phone)}
                >
                  Edit
                </button>

                <button
                  className='btn-link text-red'
                  onClick={() => onOpenDelete(phone)}
                >
                  Delete
                </button>
              </div>
            </div>

            {phone.note && (
              <p className='details-note-text'>
                <span>Notes:</span>
                <br />
                {phone.note}
              </p>
            )}

            {/* TODO: Add phone to data and rm from customer */}
            {/* <button
              className='btn-link'
              onClick={() => onSetPrimary(phone.id)}
            >
              Set primary phone number
            </button> */}
          </div>
        ))}
      </div>

      <CreateUpdateModal
        existingPhone={selectedPhone}
        customerId={customer.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onMutated={onMutated}
      />

      <DeleteModal
        id={selectedPhone?.id}
        question='Are you sure you would like to delete this phone number?'
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onDelete={onDelete}
      />
    </div>
  );
}
