import { Modal } from 'antd';
import { File } from 'models/file';

interface Props {
  pictures: File[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onOpenUpload: () => void;
}

export default function PicturesListModal({
  pictures,
  isOpen,
  setIsOpen,
  onOpenUpload,
}: Props) {
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title='Project photos'
      open={isOpen}
      closeIcon={null}
      closable={false}
      footer={null}
      width={700}
      className='app-modal pictures-modal'
    >
      <div className='modal-body'>
        <div className='pictures-collection-wrapper'>
          <div className='collection-row'>
            {pictures.map((picture, index) => (
              <div key={index} className='collection-img-col'>
                <a
                  href={picture.url}
                  target='_blank'
                  className='img-btn'
                >
                  <img
                    src={picture.url}
                    alt={picture.name}
                    className='img'
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='modal-footer'>
        <button
          className='btn-secondary'
          onClick={onClose}
          type='button'
        >
          Close
        </button>

        <button
          className='btn-primary'
          onClick={onOpenUpload}
          type='submit'
        >
          Upload
        </button>
      </div>
    </Modal>
  );
}
