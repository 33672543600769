import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 64, color: '#000' }} spin />
);

interface Props {
  customClass?: string;
}

export default function Loader({
  customClass = 'section-loader',
}: Props) {
  return (
    <section className={customClass}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12'>
            <Spin indicator={antIcon} size='large' />
          </div>
        </div>
      </div>
    </section>
  );
}
