import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description?: string;
}

export default function SEO({ title, description }: Props) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property='og:title' content={title} />
      <meta name='twitter:title' content={title} />

      <meta name='description' content={description} />
      <meta name='twitter:description' content={description} />
      <meta name='og:description' content={description} />

      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />

      <link
        rel='mask-icon'
        href='/safari-pinned-tab.svg'
        color='#1a38b4'
      />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='theme-color' content='#ffffff' />
    </Helmet>
  );
}
