import Sidebar from 'components/layout/Sidebar';
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const AuthLayout = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isAuthenticated) {
    return <Navigate to='customers' replace />;
  }

  return <Outlet />;
};

export const DashboardLayout = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isAuthenticated) {
    return <Navigate to='login' />;
  }

  return (
    <>
      <Sidebar />

      <main className='content'>
        <Outlet />
      </main>
    </>
  );
};
