import { CURRENT_USER, IS_AUTHENTICATED } from 'actions/types';
import { Dispatch } from 'redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import employeeService from 'services/employeeService';

export const validateSession = () => {
  return async (dispatch: Dispatch) => {
    try {
      const auth = getAuth();

      onAuthStateChanged(auth, async (authUser) => {
        try {
          if (authUser) {
            const employee = await employeeService.getMe();

            dispatch({ type: CURRENT_USER, payload: employee });
            dispatch({ type: IS_AUTHENTICATED, payload: true });
          } else {
            dispatch({ type: CURRENT_USER, payload: undefined });
            dispatch({ type: IS_AUTHENTICATED, payload: false });
          }
        } catch (error) {
          dispatch({ type: CURRENT_USER, payload: undefined });
          dispatch({ type: IS_AUTHENTICATED, payload: false });
        }
      });
    } catch (error) {
      dispatch({ type: IS_AUTHENTICATED, payload: false });
    }
  };
};

/**
 * If every offline utilize this:
 * 
 * const employeeMock: Employee = {
        id: 0,
        firstName: 'Mikhail',
        lastName: 'L',
        email: 'mlozovyywd@gmail.com',
        employeeType: 'ADMIN',
        specialId: '1234-5678-abcd-efgh',
        firebaseUuid: '1234567890',
      };

      dispatch({ type: CURRENT_USER, payload: employeeMock });
      dispatch({ type: IS_AUTHENTICATED, payload: true });
 */
