import { Employee } from 'models/employee';
import { createApiClient } from './authenticationService';

const getMe = async (): Promise<Employee> => {
  try {
    const client = await createApiClient();

    const employee = await client.get('/employee/me');

    return employee.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getMe,
};
