import { Link, useLocation } from 'react-router-dom';

import ImperialLogo from 'assets/images/logo.png';

import { navItems } from 'data/layout/sidebar';

export default function Sidebar() {
  const location = useLocation();

  const { pathname } = location;

  return (
    <div className='sidebar-wrapper'>
      <div className='sidebar-header'>
        <a href='https://imperialbuildingmi.com/' target='_blank'>
          <img
            src={ImperialLogo}
            alt='imperial logo'
            className='header-img'
          />
        </a>
      </div>

      <div className='sidebar-content'>
        {navItems.map((item, index) => (
          <Link
            to={item.path}
            className={`content-link${
              item.acceptedPaths.includes(
                pathname.split('/')[1] ?? pathname
              )
                ? ' active'
                : ''
            }`}
            key={index}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
}
