import useMessage from 'antd/es/message/useMessage';
import addIcon from 'assets/svg/icons/add-icon.svg';
import { useGetCustomer } from 'lib/useCustomer';
import { CustomerEmail, RetrievedCustomer } from 'models/customer';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import emailService from 'services/emailService';
import CreateUpdateModal from './email/CreateUpdateModal';
import DeleteModal from './modals/DeleteModal';

interface Props {
  setLoading: (isLoading: boolean) => void;
  customer: RetrievedCustomer;
}

export default function EmailsBlock({ setLoading, customer }: Props) {
  const [messageApi, contextHolder] = useMessage();
  const params = useParams<{ specialId?: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<
    CustomerEmail | undefined
  >(undefined);

  const { mutate } = useGetCustomer(params.specialId ?? '');

  const onSetPrimary = async (id: number) => {
    setLoading(true);

    await emailService.updatePrimaryEmail(id, customer.id);

    await mutate();

    setLoading(false);
  };

  const onOpenCreate = () => {
    setSelectedEmail(undefined);
    setIsOpen(true);
  };

  const onMutated = async () => {
    await mutate();
  };

  const onEdit = (email: CustomerEmail) => {
    setSelectedEmail(email);
    setIsOpen(true);
  };

  const onOpenDelete = (email: CustomerEmail) => {
    setSelectedEmail(email);
    setIsDeleteOpen(true);
  };

  const onDelete = async (id?: number) => {
    if (id) {
      await emailService.deleteEmail(id);

      await mutate();

      messageApi.open({
        type: 'success',
        content: 'Successfully deleted email!',
      });

      setSelectedEmail(undefined);
      setIsDeleteOpen(false);
    }
  };

  return (
    <div className='details-wrapper'>
      <div className='details-header'>
        <p className='header-title'>Email</p>

        {customer.email !== '' && (
          <button className='header-btn' onClick={onOpenCreate}>
            <img src={addIcon} alt='add icon' className='btn-icon' />
          </button>
        )}
      </div>

      <div className='details-body'>
        <div className='details-block'>
          <div className='details-flex'>
            <div className='details-text-wrapper'>
              <p className='details-text'>
                {customer.email !== ''
                  ? customer.email
                  : 'NO PRIMARY EMAIL ADDRESS'}
              </p>
            </div>
          </div>

          {customer.email !== '' && (
            <p className='details-primary-text'>
              Primary email address
            </p>
          )}
        </div>

        {customer.emails.map((email, index) => (
          <div className='details-block' key={index}>
            <div className='details-flex'>
              <div className='details-text-wrapper'>
                <p className='details-text'>{email.email ?? '-'}</p>
              </div>

              <div className='details-btn-wrapper'>
                <button
                  className='btn-link'
                  onClick={() => onEdit(email)}
                >
                  Edit
                </button>

                <button
                  className='btn-link text-red'
                  onClick={() => onOpenDelete(email)}
                >
                  Delete
                </button>
              </div>
            </div>

            {email.note && (
              <p className='details-note-text'>
                <span>Notes:</span>
                <br />
                {email.note}
              </p>
            )}

            {/* TODO: Add email to data and rm from customer */}
            {/* <button
              className='btn-link'
              onClick={() => onSetPrimary(email.id)}
            >
              Set primary email address
            </button> */}
          </div>
        ))}
      </div>

      <CreateUpdateModal
        existingEmail={selectedEmail}
        customerId={customer.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onMutated={onMutated}
      />

      <DeleteModal
        id={selectedEmail?.id}
        question='Are you sure you would like to delete this address?'
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onDelete={onDelete}
      />
    </div>
  );
}
