import {
  CURRENT_USER,
  CUSTOMERS,
  FILES,
  IS_AUTHENTICATED,
} from 'actions/types';
import { Customer } from 'models/customer';
import { Employee } from 'models/employee';
import { File } from 'models/file';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

export interface RootState {
  app: StateModel;
}

export const useAppSelector: TypedUseSelectorHook<RootState> =
  useSelector;

const INIT_STATE = {
  isAuthenticated: false,
  currentUser: undefined,
  isAppLoaded: false,
  customers: [],
  files: [],
};

interface StateModel {
  isAuthenticated: boolean;
  currentUser: Employee | undefined;

  isAppLoaded: boolean;

  customers: Customer[];
  files: File[];
}

export default function AccountReducer(
  state: StateModel = INIT_STATE,
  action: any
) {
  switch (action.type) {
    case IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload,
        isAppLoaded: true,
      };
    }

    case CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }

    case CUSTOMERS: {
      return {
        ...state,
        customers: action.payload,
      };
    }

    case FILES: {
      return {
        ...state,
        files: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
