import { RcFile } from 'antd/es/upload';
import Compressor from 'compressorjs';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { Customer } from 'models/customer';
import { File, FileType, SaveFiles, UploadedFile } from 'models/file';
import { createApiClient } from './authenticationService';

const retrieveAllFiles = async (): Promise<File[]> => {
  try {
    const client = await createApiClient();

    const files = await client.get('/file');

    return files.data;
  } catch (error) {
    throw error;
  }
};

const retrieveCustomerFiles = async (
  specialId: string
): Promise<File[]> => {
  try {
    const client = await createApiClient();

    const { data } = await client.get(`/file/customer/${specialId}`);

    return data;
  } catch (error) {
    throw error;
  }
};

const uploadFiles = async (
  files: RcFile[],
  specialId: Customer['specialId'],
  fileType: FileType,
  addressId?: number
) => {
  try {
    const uploadedFiles: UploadedFile[] = [];

    const imgTypes = ['jpg', 'jpeg', 'png', 'webp', 'heif', 'hevc'];

    for (const file of files) {
      if (imgTypes.some((t) => file.name.toLowerCase().includes(t))) {
        const compressedImage = await compressFile(file);

        const uploaded = await uploadFile(
          file.name,
          compressedImage,
          specialId,
          fileType
        );

        uploadedFiles.push(uploaded);
      } else {
        const uploaded = await uploadFile(
          file.name,
          file,
          specialId,
          fileType
        );

        uploadedFiles.push(uploaded);
      }
    }

    await saveUploadedFiles(
      uploadedFiles,
      specialId,
      fileType,
      addressId
    );
  } catch (error) {
    throw error;
  }
};

const uploadFile = async (
  fileName: string,
  file: RcFile | Blob,
  specialId: Customer['specialId'],
  fileType: FileType
): Promise<UploadedFile> => {
  try {
    const storage = getStorage();

    const storageRef = ref(
      storage,
      `files/${specialId}/${fileType}/${fileName}`
    );

    const uploaded = await uploadBytes(storageRef, file);

    const downloadUrl = await getDownloadURL(uploaded.ref);

    return {
      name: uploaded.ref.name,
      url: downloadUrl,
    };
  } catch (error) {
    throw error;
  }
};

const compressFile = (file: RcFile) => {
  return new Promise((resolve: (f: Blob) => void) => {
    new Compressor(file, {
      quality: 0.85,
      maxWidth: 800,

      success(compressedImage) {
        resolve(compressedImage);
      },
    });
  });
};

const saveUploadedFiles = async (
  files: UploadedFile[],
  specialId: Customer['specialId'],
  fileType: FileType,
  addressId?: number
): Promise<File[]> => {
  try {
    const client = await createApiClient();

    const body: SaveFiles = {
      fileType,
      specialId,
      files,
      addressId,
    };

    const fileSaved = await client.post('/file/save', body);

    return fileSaved.data;
  } catch (error) {
    throw error;
  }
};

const updateFile = async (file: Partial<File>): Promise<File> => {
  try {
    const client = await createApiClient();

    const { data } = await client.patch('/file', file);

    return data;
  } catch (error) {
    throw error;
  }
};

export default {
  retrieveAllFiles,
  retrieveCustomerFiles,
  uploadFiles,
  uploadFile,
  saveUploadedFiles,
  updateFile,
};
