import { Modal } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { InputField, MultiInputField } from 'components/Fields';
import Loader from 'components/core/Loader';
import { CustomerEmail } from 'models/customer';
import { useEffect, useState } from 'react';
import emailService from 'services/emailService';

interface Props {
  existingEmail?: CustomerEmail;
  customerId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onMutated: () => void;
}

type CreateUpdateEmail = Pick<
  Partial<CustomerEmail>,
  'email' | 'note'
>;

const INIT_PHONE: CreateUpdateEmail = {
  email: '',
  note: '',
};

export default function CreateUpdateModal({
  existingEmail,
  customerId,
  isOpen,
  setIsOpen,
  onMutated,
}: Props) {
  const [messageApi, contextHolder] = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const INIT_FORM = existingEmail
    ? {
        email: existingEmail.email,
        note: existingEmail.note,
      }
    : INIT_PHONE;

  const [emailForm, setEmailForm] =
    useState<CreateUpdateEmail>(INIT_FORM);

  useEffect(() => {
    setEmailForm(INIT_FORM);
  }, [existingEmail]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (existingEmail) {
        // Updating email
        await emailService.updateEmail({
          id: existingEmail.id,
          customerId,
          ...emailForm,
        });

        messageApi.open({
          type: 'success',
          content: 'Phone updated!',
        });
      } else {
        // Creating new email
        await emailService.createEmail({
          customerId,
          ...emailForm,
        });

        messageApi.open({
          type: 'success',
          content: 'New email created!',
        });

        setEmailForm(INIT_PHONE);
      }

      onMutated();

      setIsOpen(false);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      messageApi.open({
        type: 'error',
        content: (error as Error).message,
      });
    }
  };

  const onClose = () => {
    if (!existingEmail) {
      setEmailForm(INIT_PHONE);
    }

    setIsOpen(false);
  };

  const isCreateDisabled = () => {
    const requiredKeys = ['email'];

    const isPhoneIncomplete = Object.entries(emailForm).some(
      ([key, val]) => requiredKeys.includes(key) && val === ''
    );

    return isPhoneIncomplete;
  };

  return (
    <>
      {contextHolder}

      <Modal
        title={existingEmail ? 'Update email' : 'Create email'}
        open={isOpen}
        closeIcon={null}
        closable={false}
        footer={null}
        width={450}
        className='app-modal'
        destroyOnClose
      >
        {isLoading ? (
          <Loader customClass='modal-loader' />
        ) : (
          <form className='modal-form'>
            <div className='modal-body'>
              <div className='form-fields-group'>
                <InputField
                  label='Email'
                  type='email'
                  name='email'
                  value={emailForm.email ?? ''}
                  placeholder='Email address'
                  onChange={(value) =>
                    setEmailForm({
                      ...emailForm,
                      email: value.toLowerCase(),
                    })
                  }
                />
              </div>

              <div className='form-fields-group'>
                <MultiInputField
                  label='Notes'
                  name='note'
                  value={emailForm.note ?? ''}
                  placeholder='Notes'
                  onChange={(value) =>
                    setEmailForm({
                      ...emailForm,
                      note: value,
                    })
                  }
                />
              </div>
            </div>

            <div className='modal-footer'>
              <button
                className='btn-secondary'
                onClick={onClose}
                type='button'
              >
                Cancel
              </button>

              <button
                className='btn-primary'
                onClick={onSubmit}
                disabled={isCreateDisabled()}
                type='submit'
              >
                {existingEmail ? 'Update' : 'Create'}
              </button>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
}
