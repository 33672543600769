import { Link } from 'react-router-dom';

import Layout from 'components/core/Layout';

export default function NotFound() {
  return (
    <Layout seoTitle='404 - Imperial Building' seoDescription=''>
      <div className='text-center'>
        <p>Page not found</p>

        <Link to='/' className='btn-primary'>
          Go home
        </Link>
      </div>
    </Layout>
  );
}
