import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { validateSession } from 'actions/auth/validate';

import Loader from 'components/core/Loader';
import NotFound from 'pages/NotFound';
import { AuthLayout, DashboardLayout } from './Layouts';
import { ROUTES, ROUTE_TYPE, RouteModel } from './routes';

export default function AppRouter() {
  const dispatch = useDispatch();

  const isAppLoaded = useSelector(
    (state: any) => state.app.isAppLoaded
  );

  const isAuthenticated = useSelector(
    (state: any) => state.app.isAuthenticated
  );

  const dispatchValidateSession = bindActionCreators(
    validateSession,
    dispatch
  );

  useEffect(() => {
    dispatchValidateSession();
  }, []);

  if (!isAppLoaded) {
    return <Loader />;
  }

  const authRoutes = ROUTES.filter(
    (route: RouteModel) => route.type === ROUTE_TYPE.AUTH
  );

  const dashboardRoutes = ROUTES.filter(
    (route: RouteModel) => route.type === ROUTE_TYPE.PRIVATE
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={<AuthLayout isAuthenticated={isAuthenticated} />}
        >
          {authRoutes.map((route, index) => (
            <Route
              path={route.path}
              element={<route.component />}
              key={index}
            />
          ))}
        </Route>

        <Route
          element={
            <DashboardLayout isAuthenticated={isAuthenticated} />
          }
        >
          {dashboardRoutes.map((route, index) => (
            <Route
              path={route.path}
              element={<route.component />}
              key={index}
            />
          ))}
        </Route>

        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
