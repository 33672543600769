import SEO from './SEO';

interface Props {
  seoTitle: string;
  seoDescription?: string;
  children: any;
}

export default function Layout({
  children,
  seoTitle,
  seoDescription,
}: Props) {
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />

      {children}
    </>
  );
}
