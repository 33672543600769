import { Modal, Select, SelectProps } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { RcFile } from 'antd/es/upload';
import closeIcon from 'assets/svg/close.svg';
import { SelectField, UploadField } from 'components/Fields';
import Loader from 'components/core/Loader';

import { debounce } from 'lodash';
import { FileType } from 'models/file';
import { useEffect, useMemo, useRef, useState } from 'react';
import addressService from 'services/addressService';
import customerService from 'services/customerService';
import fileService from 'services/fileService';

interface Props {
  prefilled?: UploadFile;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onNext: () => void;
}

type UploadFile = {
  customerSpecialId: string;
  customerInfo: string;
  addressId?: number;
  addressInfo?: string;
  fileType: FileType;
  files: RcFile[];
};

const INIT_FILE: UploadFile = {
  customerSpecialId: '',
  customerInfo: '',
  fileType: 'PROPOSAL',
  files: [],
};

interface SelectOption {
  label: string;
  value: string;
}

const typeOptions: SelectOption[] = [
  {
    value: 'PROPOSAL',
    label: 'Proposal',
  },
  { value: 'INVOICE', label: 'Invoice' },
  { value: 'PICTURE', label: 'Picture' },
  { value: 'OTHER', label: 'Other' },
];

export default function UploadModal({
  prefilled,
  isOpen,
  setIsOpen,
  onNext,
}: Props) {
  const [messageApi, contextHolder] = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const [fetchedCustomers, setFetchedCustomers] = useState<
    SelectProps['options']
  >([]);
  const [fetchedAddresses, setFetchedAddresses] = useState<
    SelectProps['options']
  >([]);

  const [isFetching, setIsFetching] = useState(false);

  const fetchRef = useRef(0);

  const [fileForm, setFileForm] = useState<UploadFile>(INIT_FILE);

  const isCreateDisabled =
    fileForm.customerSpecialId === '' || fileForm.files.length === 0;

  useEffect(() => {
    if (prefilled && isOpen) setFileForm(prefilled);
  }, [JSON.stringify(prefilled), isOpen]);

  useEffect(() => {
    const getAddresses = async () => {
      if (fileForm.customerSpecialId !== '') {
        const addresses =
          await addressService.retrieveAddressesByCustomer(
            fileForm.customerSpecialId
          );

        setFetchedAddresses(
          addresses.map((addr) => ({
            label: `${addr.line1}${
              addr.line2 ? ' ' + addr.line2 : ''
            }, ${addr.city} ${addr.stateAbbr} ${addr.zip}`,
            value: addr.id,
          }))
        );
      }
    };

    getAddresses();
  }, [JSON.stringify(fileForm)]);

  const debounceFetcher = useMemo(() => {
    const debounceTimeout = 800;

    const loadOptions = (value: string) => {
      if (value === '') return;

      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setFetchedCustomers([]);
      setIsFetching(true);

      customerService.searchCustomers(value).then((customers) => {
        if (fetchId !== fetchRef.current) {
          setIsFetching(false);

          return;
        }

        setFetchedCustomers(
          customers.map((c) => ({
            label: `${c?.firstName} ${c?.lastName}`,
            value: c?.specialId,
          }))
        );

        setIsFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, []);

  const onCreate = async () => {
    try {
      setIsLoading(true);
      const { customerSpecialId, fileType, files, addressId } =
        fileForm;

      // Don't want to store -1 on the database
      const addressIdFixed =
        !addressId || addressId === -1 ? undefined : addressId;

      await fileService.uploadFiles(
        files,
        customerSpecialId,
        fileType,
        addressIdFixed
      );

      messageApi.open({
        type: 'success',
        content: 'File(s) uploaded!',
      });

      setFileForm(INIT_FILE);

      setIsOpen(false);
      onNext();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      messageApi.open({
        type: 'error',
        content: (error as Error).message,
      });
    }
  };

  const onClose = () => {
    setFileForm(INIT_FILE);

    setIsOpen(false);
  };

  const onRemoveFileAt = (index: number) => {
    const files = fileForm.files;
    files.splice(index, 1);

    setFileForm({
      ...fileForm,
      files,
    });
  };

  const onSelectFiles = (files: RcFile[]) => {
    let { fileType } = fileForm;

    setFileForm({
      ...fileForm,
      fileType,
      files: [...fileForm.files, ...files],
    });
  };

  return (
    <>
      {contextHolder}

      <Modal
        title='Upload file(s)'
        open={isOpen}
        closeIcon={null}
        closable={false}
        footer={null}
        width={450}
        className='app-modal'
        destroyOnClose
      >
        {isLoading ? (
          <Loader customClass='modal-loader' />
        ) : (
          <form className='modal-form'>
            <div className='modal-body'>
              <div className='form-fields-group'>
                <div className='input-field-wrapper'>
                  <p className='input-label'>Select customer</p>

                  <Select
                    showSearch
                    value={
                      fileForm.customerInfo !== ''
                        ? fileForm.customerInfo
                        : null
                    }
                    onSelect={(_, option) =>
                      setFileForm({
                        ...fileForm,
                        customerSpecialId: (option as SelectOption)
                          .value,
                        customerInfo: (option as SelectOption).label,
                      })
                    }
                    onSearch={debounceFetcher}
                    options={fetchedCustomers}
                    filterOption={false}
                    placeholder='Search by name, email, or phone'
                    className='input-field-full'
                    notFoundContent={null}
                    defaultActiveFirstOption={false}
                    loading={isFetching}
                  />
                </div>
              </div>

              {fileForm.customerSpecialId !== '' && (
                <div className='form-fields-group'>
                  <div className='input-field-wrapper'>
                    <p className='input-label'>
                      Select address (optional)
                    </p>

                    <Select
                      value={
                        fileForm.addressInfo !== ''
                          ? fileForm.addressInfo
                          : null
                      }
                      onSelect={(_, option) =>
                        setFileForm({
                          ...fileForm,
                          addressId: parseInt(
                            (option as SelectOption).value
                          ),
                          addressInfo: (option as SelectOption).label,
                        })
                      }
                      options={fetchedAddresses}
                      placeholder='Select address'
                      className='input-field-full'
                      allowClear
                      onClear={() =>
                        setFileForm({
                          ...fileForm,
                          addressId: undefined,
                          addressInfo: undefined,
                        })
                      }
                    />
                  </div>
                </div>
              )}

              <div className='form-fields-group'>
                <SelectField
                  label='Select file(s) type'
                  value={fileForm.fileType}
                  onChange={(value) =>
                    setFileForm({
                      ...fileForm,
                      fileType: value as FileType,
                    })
                  }
                  options={typeOptions}
                  placeholder='File type'
                />
              </div>

              <div className='form-fields-group'>
                <UploadField
                  label='File(s)'
                  name='files'
                  accept='.doc,.docx,.pdf,.png,.jpeg,.jpg,.heif,.hevc,.txt'
                  onChange={onSelectFiles}
                />
              </div>

              <div className='form-uploaded-files'>
                {fileForm.files.map((f, index) => (
                  <div className='file-wrapper' key={index}>
                    <a
                      href={URL.createObjectURL(f)}
                      target='_blank'
                      className='file-link'
                    >
                      {f.name}
                    </a>

                    <button
                      className='file-remove-btn'
                      onClick={() => onRemoveFileAt(index)}
                      type='button'
                    >
                      <img
                        src={closeIcon}
                        alt='close icon'
                        className='remove-icon'
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className='modal-footer'>
              <button
                className='btn-secondary'
                onClick={onClose}
                type='button'
              >
                Cancel
              </button>

              <button
                className='btn-primary'
                onClick={onCreate}
                disabled={isCreateDisabled}
                type='button'
              >
                Upload
              </button>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
}
