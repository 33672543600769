import { CustomerPhone, UnsavedCustomerPhone } from 'models/customer';
import { createApiClient } from './authenticationService';

const updatePrimaryPhone = async (
  CustomerPhoneId: number,
  customerId: number
) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/phone/primary', {
      id: CustomerPhoneId,
      customerId,
    });
  } catch (error) {
    throw error;
  }
};

const createPhone = async (
  address: Partial<UnsavedCustomerPhone>
) => {
  try {
    const client = await createApiClient();

    await client.post('/customer/phone', address);
  } catch (error) {
    throw error;
  }
};

const updatePhone = async (address: Partial<CustomerPhone>) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/phone', address);
  } catch (error) {
    throw error;
  }
};

const deletePhone = async (id: number) => {
  try {
    const client = await createApiClient();

    await client.patch('/customer/phone', {
      id,
      active: false,
    });
  } catch (error) {
    throw error;
  }
};

export default {
  updatePrimaryPhone,
  createPhone,
  updatePhone,
  deletePhone,
};
